import React, { useEffect } from 'react'
import styled from 'styled-components'
import { space } from '@quiqupltd/ui-kit.sizes'
import { Modal as AntModal, Button, Form, Input, Alert } from 'antd'

const Modal = styled(AntModal)`
  width: ${space(80)}px !important;
`

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (notes: string, waypointType: string) => void
  notes: string
  isLoading: boolean
  errorMessage?: string
  waypointType: string
}

function UpdateNotesModal(props: Props): JSX.Element {
  const [form] = Form.useForm<{ notes: string }>()

  useEffect(() => {
    if (props.isOpen) {
      form.setFieldsValue({ notes: props.notes })
    }
  }, [form, props.isOpen, props.notes])

  function handleClose(): void {
    form.resetFields()
    props.onClose()
  }

  function handleSubmit(values: { notes: string }): void {
    props.onSubmit(values.notes, props.waypointType)
    form.resetFields()
  }

  return (
    <Modal
      title={`Update ${props.waypointType === 'origin' ? 'collection' : 'delivery'} notes`}
      visible={props.isOpen}
      footer={[
        <Button key="back" onClick={handleClose} disabled={props.isLoading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={form.submit} loading={props.isLoading}>
          OK
        </Button>,
      ]}
      onCancel={handleClose}
    >
      <div>
        <Form layout="horizontal" onFinish={handleSubmit} name="submission_form" form={form}>
          <Form.Item label="Notes" colon={false} name="notes">
            <Input placeholder="Call 10 min before..." />
          </Form.Item>
        </Form>
      </div>
      {props.errorMessage && <Alert message={props.errorMessage} type="error" showIcon />}
    </Modal>
  )
}

export default UpdateNotesModal
