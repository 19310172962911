import React, { useState, useMemo, useEffect, KeyboardEvent, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'
import styled from 'styled-components'
import { space } from '@quiqupltd/ui-kit.sizes'
import { ApiClient } from '@quiqupltd/quiqupjs'
import {
  Typography,
  Input,
  Row as AntRow,
  Col,
  Button,
  Select,
  Switch,
  Spin,
  notification,
  Form,
  DatePicker,
  DatePickerProps,
  message,
  Alert,
} from 'antd'

import OrderBox from './OrderBox'
import {
  OrderWithScanned,
  AllowedOrderStatesForUpdate as OrderStates,
  OrderStatesLabels,
  OrderInterface,
} from '../../types/order.type'
import { useOrderScan } from '../../hooks/order-scan'
import Routes from '../../types/routes.type'
import theme from '../../theme'
import BulkDetails from './BulkDetails'
import { useOrderUpdate, MISSION_KINDS, MissionKinds } from '../../hooks/order-update'
import downloadLabels from '../../utils/downloadLabels'
import { Depot, FailedReason, OnHoldReason, ReturnToOriginReason } from '../../types/history-order.type'
import { loadEnv } from '../../configEnv'
import moment from 'moment'
import type { SelectProps } from 'antd'

const { Title } = Typography
const { Option, OptGroup } = Select

// TYPES
interface RowProps {
  marginBottom?: number
}

export enum ORDER_TYPES {
  RECOGNIZED = 'RECOGNIZED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

interface Props
  extends RouteComponentProps<
    { [key: string]: string | undefined }, // props.match.params.myParamProp
    StaticContext, // history
    {
      selectedOrders?: OrderInterface[]
      message?: string
    } // props.location.state.myStateProp
  > {
  children: React.ReactNode
}

// STYLES
const Container = styled.div`
  padding: 0px 50px;
  padding-top: ${space(4)}px;
  .bulk-select {
    margin-right: 16px;
    width: 300px;
  }
`

const Overlay = styled.div`
  height: 100vh;
  width: 100%;
  background: ${(p) => p.theme.ghost500};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
`

const Search = styled(Input.Search)`
  width: 355px;
`

const Row = styled(AntRow)<RowProps>`
  ${(p) =>
    p.marginBottom &&
    `
    margin-bottom: ${p.marginBottom}px;
  `}
`

// HELPERS
const possitiveAudio = new Audio('https://storage.googleapis.com/quiqup-public-assets/oms/good-scan.mp3')

const negativeAudio = new Audio('https://storage.googleapis.com/quiqup-public-assets/oms/bad-scan.mp3')

const MISSION_KINDS_KEYS = Object.keys(MISSION_KINDS)

const ORDER_STATES_KEYS = Object.keys(OrderStates) as Order.State[]

const REGION_KEYS: Region.Slug[] = ['dubai', 'abuDhabi']

const DOWNLOAD_LABELS_KEY = 'download_labels'

const missionLabels: { [key: string]: string } = {
  DeliveryFromClientLocation: 'Delivery from client location',
  Collection: 'Collection from client to ...',
  DeliveryFromQuiqupDepot: 'Delivery from ...',
  Return: 'Return from ...',
  ScanningCollection: 'Collection with scanning support ...',
  ReturnCollection: 'Return collection mission',
  Transfer: 'Transfer to ...',
}

export const scanMissionIntentions: { [key: string]: string } = {
  Collection: 'collection_mission',
  DeliveryFromQuiqupDepot: 'delivery_mission',
  DeliveryFromClientLocation: 'direct_delivery_mission',
  Return: 'return_mission',
  ScanningCollection: 'scanning_collection_mission',
  ReturnCollection: 'return_collection_mission',
}

const regionLabels: { [key in Region.Slug]: Region.Label } = {
  dubai: 'Dubai',
  abuDhabi: 'Abu Dhabi',
}

const regionCodes: { [key in Region.Slug]: Region.Code } = {
  dubai: 'uae.dubai',
  abuDhabi: 'uae.abu_dhabi',
}

export type ActionType = MissionKinds | OrderStates | 'download_labels'

function requiresRegionSelection(actionType: ActionType | null): boolean {
  if (!actionType) return false
  return [
    'Collection',
    'DeliveryFromQuiqupDepot',
    'Return',
    'ScanningCollection',
    'ReturnCollection',
    'Transfer',
    OrderStates.received_at_depot,
    OrderStates.at_depot,
    OrderStates.in_transit,
    OrderStates.on_hold,
    OrderStates.scheduled,
    OrderStates.return_to_origin,
  ].includes(actionType)
}

function requiresDepotSelection(actionType: ActionType | null): boolean {
  if (!actionType) return false
  return [
    'DeliveryFromQuiqupDepot',
    'ReturnCollection',
    'Return',
    'Collection',
    'ScanningCollection',
    'Transfer',
    OrderStates.received_at_depot,
    OrderStates.at_depot,
    OrderStates.on_hold,
    OrderStates.in_transit,
    OrderStates.scheduled,
    OrderStates.return_to_origin,
  ].includes(actionType)
}

function requiresMainDepot(actionType: ActionType | null): boolean {
  if (!actionType) return false
  return ['Return', 'Collection', 'ScanningCollection', 'Transfer'].includes(actionType)
}

function requiresAddingOrUpdatingMission(actionType: ActionType | null): boolean {
  if (!actionType) return false
  return [
    'Collection',
    'DeliveryFromClientLocation',
    'DeliveryFromQuiqupDepot',
    'Return',
    'return',
    'ScanningCollection',
    'ReturnCollection',
    'Transfer',
  ].includes(actionType)
}

function getIdsFromOrders(orders: OrderWithScanned[]): string[] {
  if (orders.length > 0) {
    return orders.map((order) => order.id.toString())
  }

  return []
}

function getUuidsFromOrders(orders: OrderWithScanned[]): string[] {
  return orders.map((order) => order.uuid.toString())
}

function areInValidStates(orders: OrderWithScanned[]): boolean {
  return !orders.some((order) => ['delivery_complete', 'cancelled'].includes(order.state))
}

function getUpdatedOrders(orders: OrderWithScanned[]): [string][] {
  return getIdsFromOrders(orders).map((id) => [`${id}`])
}

export function areAllItemsScanned(orders: OrderWithScanned[]): boolean {
  if (!orders.length) return false

  return orders.every((order) => order.items.every((item) => Boolean(item.scanned)))
}

function isValidAction(
  recognizedOrders: OrderWithScanned[],
  orderAction: ActionType | null,
  region: Region.Code | null,
  newZoneText: string | null,
  missionId: string | null,
  addOrUpdateMission: string | null
): boolean {
  return (
    Boolean(recognizedOrders.length) &&
    Boolean(orderAction) &&
    (!requiresRegionSelection(orderAction) || Boolean(region)) &&
    (!requiresAddingOrUpdatingMission(orderAction) ||
      (addOrUpdateMission === 'create' && Boolean(newZoneText)) ||
      (addOrUpdateMission === 'add' && Boolean(missionId))) &&
    areAllItemsScanned(recognizedOrders)
  )
}

function getFormattedItemScannedCounts(orders: OrderWithScanned[]): [string, string?][] {
  return orders.map((order) => {
    let itemsScanned = 0
    order.items.forEach((item) => {
      if (item.scanned) itemsScanned += 1
    })

    return [`${order.id}`, `- {${itemsScanned}/${order.items.length}}`]
  })
}

function getUnrecognizedOrders(orders: string[]): [string][] {
  return orders.map((id) => [`${id}`])
}

function getFailedValues(orders: string[]): [string][] {
  return orders.map((id) => [id])
}

const defaultDeliveryBefore = moment().zone('+0400').set({ hour: 23, minute: 0 })
// COMPONENT
export default function BulkActions(props: Props): JSX.Element {
  const [orderAction, setOrderAction] = useState<ActionType | null>(null)
  const [region, setRegion] = useState<Region.Code | null>(null)
  const [depotsList, setDepotsList] = useState<string[]>([])
  const [depots, setDepots] = useState<Depot[]>([])
  const [missions, setMissions] = useState<SelectProps['options']>([])
  const [depot, setDepot] = useState<Depot | undefined>()
  const [reason, setReason] = useState<string | undefined>()
  const [onHoldReasonsList, setOnHoldReasonsList] = useState<OnHoldReason[]>([])
  const [returnToOriginReasonsList, setReturnToOriginReasonsList] = useState<ReturnToOriginReason[]>([])
  const [failedReason, setFailedReason] = useState<FailedReason>()
  const [failedReasonsList, setFailedReasonsList] = useState<FailedReason[]>([])
  const [autoUpdateEnabled, setAutoUpdateEnabled] = useState(false)
  const [newItemText, setNewItemText] = useState('')
  const [id, setNewId] = useState('')
  const [missionId, setMissionId] = useState<string | null>(null)
  const [manuallyAddedId, setManuallyAddedId] = useState(false)
  const shouldAutoUpdate = useMemo(
    () => autoUpdateEnabled && orderAction === OrderStates.received_at_depot,
    [autoUpdateEnabled, orderAction]
  )
  const [newDeliveryBefore, setNewDeliveryBefore] = useState<Date | undefined>(defaultDeliveryBefore?.toDate())
  const [updateDeliveryBeforeErrorMessage, setUpdateDeliveryBeforeErrorMessage] = useState<string | undefined>()
  const [addOrUpdateMission, setAddOrUpdateMission] = useState<string | null>(null)
  const [newZoneText, setNewZoneText] = useState('')

  const onOk = (value: DatePickerProps['value']) => {
    setNewDeliveryBefore(value?.toDate())
  }

  const { scanState, scanActions } = useOrderScan(id, orderAction, shouldAutoUpdate, region, depot)

  async function handleBulkUpdateDeliveryBefore(): Promise<void> {
    const env = await loadEnv()
    try {
      setUpdateDeliveryBeforeErrorMessage(undefined)
      await ApiClient.put({
        path: env.EX_CORE_API_URL + `/orders/batch/set_delivery_window`,
        data: { delivery_before: newDeliveryBefore, order_ids: recognizedOrderIds },
      })
      message.success(`Success! orders (ids: ${recognizedOrderIds}) delivery before has been updated`)
    } catch (error) {
      setUpdateDeliveryBeforeErrorMessage(
        `Failed to update the delivery before (orders: ${recognizedOrderIds}), you cannot update completed/cancelled orders`
      )
    }
  }

  const recognizedOrderIds = useMemo(() => getIdsFromOrders(scanState.recognizedOrders), [scanState.recognizedOrders])
  const uuids = useMemo(() => getUuidsFromOrders(scanState.recognizedOrders), [scanState.recognizedOrders])
  const areOrdersInValidStates = useMemo(
    () => areInValidStates(scanState.recognizedOrders),
    [scanState.recognizedOrders]
  )

  const orderIdsWithScannedItems = useMemo(
    () => getFormattedItemScannedCounts(scanState.recognizedOrders),
    [scanState.recognizedOrders]
  )

  function handleSearchKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key === '#') event.preventDefault()
  }

  const updatedOrderIds = useMemo(() => getUpdatedOrders(scanState.updatedOrders), [scanState.updatedOrders])

  const unrecognizedValues = useMemo(
    () => getUnrecognizedOrders(scanState.unrecognizedValues),
    [scanState.unrecognizedValues]
  )

  const failedValues = useMemo(() => getFailedValues(scanState.failedToUpdateOrders), [scanState.failedToUpdateOrders])

  const { updateState, updateActions } = useOrderUpdate(
    recognizedOrderIds,
    orderAction,
    region,
    depot,
    reason,
    newZoneText,
    failedReason,
    missionId,
    uuids
  )

  const { removeRecognizedOrder } = scanActions
  const handleRemoveOrder = useCallback(
    function handleRemoveOrder(orderId: string): void {
      removeRecognizedOrder(orderId)
    },
    [removeRecognizedOrder]
  )

  function handleNewItemTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const trimmedValue = event.target.value.trim()
    setNewItemText(trimmedValue.replace(/#/g, ''))
  }

  function handleNewZoneTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setNewZoneText(event.target.value)
  }
  function handleSelectMissionId(missionId: string): void {
    setMissionId(missionId)
  }

  function handleBulkUpdateClick(): void {
    if (orderAction === DOWNLOAD_LABELS_KEY) {
      // Download Label
      downloadLabels(scanState.recognizedOrders)
    } else {
      updateActions.updateOrders(addOrUpdateMission)
    }
  }

  async function handleAddNewOrder(orderId: string): Promise<void> {
    if (!orderAction) return

    setManuallyAddedId(true)
    setNewId(orderId)
    setNewItemText('')
  }

  async function fetchDepots(regionName: Region.Code, mainDepot: boolean): Promise<Depot[]> {
    const env = await loadEnv()
    const response: { depots: Depot[] } = await ApiClient.get({
      path: env.EX_CORE_API_URL + '/api/depots',
      params: { region: regionName, mainDepot },
    })
    return response.depots
  }

  async function fetchMissions(missionIdOrZone: string | null): Promise<string[]> {
    const env = await loadEnv()
    const response: { results: string[] } = await ApiClient.get({
      path: env.EX_CORE_API_URL + '/api/missions',
      params: missionIdOrZone ? { value: missionIdOrZone } : {},
    })
    return response.results
  }

  async function fetchOnHoldReasons(): Promise<any> {
    const env = await loadEnv()
    return ApiClient.get({ path: env.EX_CORE_API_URL + '/orders/states/on_hold_reasons' })
  }

  async function fetchReturnToOriginReasons(): Promise<any> {
    const env = await loadEnv()
    return ApiClient.get({ path: env.EX_CORE_API_URL + '/orders/states/return_to_origin_reasons' })
  }

  async function fetchDeliveryFailedReasons(): Promise<any> {
    const env = await loadEnv()
    return ApiClient.get({ path: env.EX_CORE_API_URL + '/courier/delivery_failure_reasons' })
  }

  async function fetchCollectionFailedReasons(): Promise<any> {
    const env = await loadEnv()
    return ApiClient.get({ path: env.EX_CORE_API_URL + '/courier/delivery_failure_reasons?delivery_type=collection' })
  }

  function getDepotNames(depotList: Depot[]) {
    return depotList.map((depot: { name: string }) => depot.name)
  }

  async function orderActionChange(value: ActionType): Promise<void> {
    if (autoUpdateEnabled) {
      setAutoUpdateEnabled(false)
    }

    setOrderAction(value)

    if (!requiresRegionSelection(value)) {
      setRegion(null)
      setDepot(undefined)
    }

    if (value === OrderStates.on_hold && onHoldReasonsList.length === 0) {
      const onHoldReasons = await fetchOnHoldReasons()
      setOnHoldReasonsList(onHoldReasons)
    }

    if (value === OrderStates.return_to_origin && returnToOriginReasonsList.length === 0) {
      const returnToOriginReasons = await fetchReturnToOriginReasons()
      setReturnToOriginReasonsList(returnToOriginReasons)
    }

    if (value === OrderStates.collection_failed) {
      const collectionFailedReasons = await fetchCollectionFailedReasons()
      setFailedReasonsList(collectionFailedReasons)
    }

    if (value === OrderStates.delivery_failed) {
      const deliveryFailedReasons = await fetchDeliveryFailedReasons()
      setFailedReasonsList(deliveryFailedReasons)
    }

    if (requiresDepotSelection(value) && region) {
      const depots = await fetchDepots(region, requiresMainDepot(value))

      setDepots(depots)

      const depotNames = getDepotNames(depots)
      setDepotsList(depotNames)
    }
  }

  async function regionChange(selectedRegionSlug: Region.Slug): Promise<void> {
    const selectedRegion = regionCodes[selectedRegionSlug]

    setRegion(selectedRegion)

    if (selectedRegion !== region) {
      setDepot(undefined)
    }

    if (requiresDepotSelection(orderAction)) {
      const depots = await fetchDepots(selectedRegion, requiresMainDepot(orderAction))
      setDepots(depots)

      const depotNames = getDepotNames(depots)
      setDepotsList(depotNames)
    }
  }

  function depotChange(selectedDepot: string): void {
    setDepot(depots.find((depot) => depot.name === selectedDepot))
  }

  function reasonChange(selectedReason: string): void {
    setReason(onHoldReasonsList.find((reason) => reason.title === selectedReason)?.title)
  }

  function rtoReasonChange(selectedReason: string): void {
    setReason(returnToOriginReasonsList.find((reason) => reason.title === selectedReason)?.title)
  }

  function failedReasonChange(selectedReason: string): void {
    setFailedReason(failedReasonsList.find((reason) => reason.uid === selectedReason))
  }

  async function actionSearch(value: string): Promise<void> {
    if (requiresAddingOrUpdatingMission(orderAction) && addOrUpdateMission === 'add') {
      const missions = await fetchMissions(value)
      const transformedMissions = missions.map((mission) => {
        return {
          label: mission,
          value: mission,
        }
      })

      setMissions(transformedMissions)
    }
  }

  let selectedOrders: OrderInterface[] = []
  if (props.location.state) {
    selectedOrders = props.location.state.selectedOrders || []
  }

  useEffect(() => {
    if (addOrUpdateMission === 'add') {
      const setDefaultMissions = async () => {
        const missions = await fetchMissions(null)
        const transformedMissions = missions.map((mission) => {
          return {
            label: mission,
            value: mission,
          }
        })

        setMissions(transformedMissions)
      }
      setDefaultMissions()
    } else {
      setMissionId(null)
    }
  }, [addOrUpdateMission])

  useEffect(() => {
    if (selectedOrders.length) {
      scanActions.addRecognizedBatch(selectedOrders)
      props.history.replace({
        pathname: Routes.BULK_ACTIONS,
        state: {},
      })
    }
  }, [selectedOrders, scanActions, props.history])

  useEffect(() => {
    if (id.length) {
      setNewId('')
    }
  }, [id])

  useEffect(() => {
    if (updateState.successMessage) {
      props.history.push({
        pathname: Routes.ROOT,
        state: {
          message: updateState.successMessage,
        },
      })
    }
  }, [updateState.successMessage, props.history])

  useEffect(() => {
    if (updateState.error) {
      let errorMessage = updateState.error
      if (updateState.error.includes(':failed_check_validate_print_label')) {
        errorMessage = `Order ${recognizedOrderIds}: Address has been updated, please print the label`
      }
      notification.warn({
        message: 'Error',
        description: errorMessage,
        duration: 0,
      })
    }
  }, [recognizedOrderIds, updateState.error])

  useEffect(() => {
    if ((scanState.updatedOrders.length || scanState.recognizedOrders.length) && manuallyAddedId && !scanState.error) {
      possitiveAudio.play()
      setManuallyAddedId(false)
    }
  }, [scanState.updatedOrders, scanState.recognizedOrders, manuallyAddedId, scanState.error])

  useEffect(() => {
    if (scanState.failedToUpdateOrders.length || scanState.unrecognizedValues.length) {
      negativeAudio.play()
    }
  }, [scanState.failedToUpdateOrders, scanState.unrecognizedValues])

  return (
    <Container>
      {scanState.loadingBatch && (
        <Overlay>
          <Spin />
        </Overlay>
      )}
      <Title level={2}>Bulk actions</Title>
      <Row marginBottom={space(4)}>
        <Col lg={24}>
          <Select
            placeholder="Action"
            onChange={orderActionChange}
            className="bulk-select"
            data-testid="select_state"
            autoFocus
          >
            <OptGroup label="Create Mission">
              {MISSION_KINDS_KEYS.map((kind: string) => (
                <Option value={kind} key={kind} data-testid={`kind-${kind}-option`}>
                  {missionLabels[kind]}
                </Option>
              ))}
            </OptGroup>
            <OptGroup label="Change State">
              {ORDER_STATES_KEYS.map((state) => (
                <Option value={state} key={state} data-testid={`state-${state}-option`}>
                  {OrderStatesLabels[state]}
                </Option>
              ))}
            </OptGroup>
            <OptGroup label="Other Actions">
              <Option
                value={DOWNLOAD_LABELS_KEY}
                key={DOWNLOAD_LABELS_KEY}
                data-testid={`${DOWNLOAD_LABELS_KEY}-option`}
              >
                Download Labels
              </Option>
            </OptGroup>
          </Select>
          {requiresRegionSelection(orderAction) && (
            <Select placeholder="Region" onChange={regionChange} className="bulk-select" data-testid="select_region">
              {REGION_KEYS.map((regionKey) => (
                <Option value={regionKey} key={regionKey} data-testid={`region-${regionKey}-option`}>
                  {regionLabels[regionKey]}
                </Option>
              ))}
            </Select>
          )}
          {requiresDepotSelection(orderAction) && (
            <Select
              placeholder="Depot"
              value={depot?.name}
              onChange={depotChange}
              className="bulk-select"
              data-testid="select_depot"
            >
              {depotsList.map((depot, index) => (
                <Option value={depot} key={index} data-testid={`depot-${depot}-option`}>
                  {depot}
                </Option>
              ))}
            </Select>
          )}
          {orderAction === OrderStates.on_hold && (
            <Select
              placeholder="Reason"
              value={reason}
              onChange={reasonChange}
              className="bulk-select"
              data-testid="select_reason"
            >
              {onHoldReasonsList.map((reason, index) => (
                <Option
                  title={reason?.tooltip}
                  value={reason?.title}
                  key={index}
                  data-testid={`reason-${reason}-option`}
                >
                  {reason?.title}
                </Option>
              ))}
            </Select>
          )}
          {orderAction === OrderStates.return_to_origin && (
            <Select
              placeholder="Reason"
              value={reason}
              onChange={rtoReasonChange}
              className="bulk-select"
              data-testid="select_reason"
            >
              {returnToOriginReasonsList.map((reason, index) => (
                <Option
                  title={reason?.tooltip}
                  value={reason?.title}
                  key={index}
                  data-testid={`rto-reason-${reason}-option`}
                >
                  {reason?.title}
                </Option>
              ))}
            </Select>
          )}
          {(orderAction === OrderStates.delivery_failed || orderAction === OrderStates.collection_failed) && (
            <Select
              placeholder="Reason"
              value={failedReason?.title}
              onChange={failedReasonChange}
              className="bulk-select"
              data-testid="select_reason"
            >
              {failedReasonsList.map((reason, index) => (
                <Option title={reason?.title} value={reason?.uid} key={index} data-testid={`reason-${reason}-option`}>
                  {reason?.title}
                </Option>
              ))}
            </Select>
          )}
          {orderAction === OrderStates.received_at_depot && (
            <>
              Auto update {'  '}
              <Switch onChange={setAutoUpdateEnabled} checked={autoUpdateEnabled} data-testid="enable-auto-update" />
            </>
          )}
        </Col>
      </Row>
      {requiresAddingOrUpdatingMission(orderAction) && (
        <Row marginBottom={space(4)}>
          <Col lg={24}>
            <Select
              placeholder="Create or update a mission"
              onChange={(value) => setAddOrUpdateMission(value)}
              className="bulk-select"
              data-testid="select_option"
            >
              <Option value={'add'} key={1} data-testid="add-orders-into-mission">
                Add orders into mission
              </Option>
              <Option value={'create'} key={2} data-testid="create-new-mission">
                Create new mission
              </Option>
            </Select>

            {addOrUpdateMission === 'create' && (
              <Input
                className="bulk-select"
                placeholder="Add zone or area"
                value={newZoneText}
                required
                onChange={handleNewZoneTextChange}
                onKeyDown={handleSearchKeyDown}
                data-testid="zone-input"
              />
            )}

            {addOrUpdateMission === 'add' && (
              <Select
                showSearch
                className="bulk-select"
                placeholder="Mission id or zone"
                options={missions}
                onSearch={actionSearch}
                onKeyDown={handleSearchKeyDown}
                onSelect={handleSelectMissionId}
                data-testid="mission-id-or-zone-input"
              />
            )}
          </Col>
        </Row>
      )}
      <Row marginBottom={space(4)}>
        <Col lg={24}>
          <Search
            placeholder="Add parcel id"
            enterButton={
              <Button
                type="primary"
                data-testid="add-button"
                disabled={!orderAction || (requiresRegionSelection(orderAction) && !region)}
              >
                Add
              </Button>
            }
            onSearch={handleAddNewOrder}
            value={newItemText}
            onChange={handleNewItemTextChange}
            onKeyDown={handleSearchKeyDown}
          />
        </Col>
      </Row>
      {areOrdersInValidStates ? (
        <Row marginBottom={space(4)}>
          <Col lg={24}>
            <Form layout="horizontal" hideRequiredMark>
              <Form.Item name="bulkDeliveryBefore">
                <DatePicker
                  data-testid={'bulkDeliveryBefore'}
                  placeholder="Select delivery before"
                  disabled={!recognizedOrderIds?.length}
                  showTime
                  showNow={false}
                  format="YYYY-MM-DD HH"
                  defaultValue={defaultDeliveryBefore}
                  onOk={(value: DatePickerProps['value']) => onOk(value)}
                />
                &nbsp;
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleBulkUpdateDeliveryBefore}
                  disabled={!recognizedOrderIds?.length}
                  data-testid="bulk-update-delivery-before"
                >
                  Bulk Update Delivery Before
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={6} order={4}>
            {updateDeliveryBeforeErrorMessage && (
              <Alert message={updateDeliveryBeforeErrorMessage} type="error" showIcon />
            )}
          </Col>
        </Row>
      ) : null}
      <Row marginBottom={space(4)}>
        {shouldAutoUpdate ? (
          <Col lg={12}>
            <Row marginBottom={space(4)}>
              <Col lg={24}>
                <OrderBox
                  title={`Not updated - Multiple parcel shipments (${recognizedOrderIds.length})`}
                  orders={orderIdsWithScannedItems}
                  type={ORDER_TYPES.RECOGNIZED}
                  closable={false}
                  tagsColour={theme.cornFlower500}
                />
              </Col>
            </Row>
            <Row marginBottom={space(4)}>
              <Col lg={24}>
                <OrderBox
                  title={`Updated (${updatedOrderIds.length})`}
                  orders={updatedOrderIds}
                  type={ORDER_TYPES.RECOGNIZED}
                  closable={false}
                  tagsColour={theme.cornFlower500}
                />
              </Col>
            </Row>
            <Row marginBottom={space(4)}>
              <Col lg={24}>
                <OrderBox
                  title={`Unrecognized (${scanState.unrecognizedValues.length})`}
                  orders={unrecognizedValues}
                  type={ORDER_TYPES.UNRECOGNIZED}
                  closable={false}
                  tagsColour={theme.peach500}
                />
              </Col>
            </Row>
            <Row marginBottom={space(4)}>
              <Col lg={24}>
                <OrderBox
                  title={`Failed (${scanState.failedToUpdateOrders.length})`}
                  orders={failedValues}
                  type={ORDER_TYPES.UNRECOGNIZED}
                  closable={false}
                  tagsColour={theme.peach500}
                />
              </Col>
            </Row>
          </Col>
        ) : (
          <Col lg={12}>
            <Row marginBottom={space(4)}>
              <Col lg={24}>
                <OrderBox
                  title={`Recognized (${recognizedOrderIds.length})`}
                  orders={orderIdsWithScannedItems}
                  onRemoveOrder={handleRemoveOrder}
                  type={ORDER_TYPES.RECOGNIZED}
                  closable
                  tagsColour={theme.cornFlower500}
                  shouldCopyToClipboard={true}
                />
              </Col>
            </Row>
            <Row marginBottom={space(4)}>
              <Col lg={24}>
                <OrderBox
                  title={`Unrecognized (${scanState.unrecognizedValues.length})`}
                  orders={unrecognizedValues}
                  type={ORDER_TYPES.UNRECOGNIZED}
                  closable={false}
                  tagsColour={theme.peach500}
                />
              </Col>
            </Row>
            <Row>
              <Button
                type="primary"
                disabled={
                  !isValidAction(
                    scanState.recognizedOrders,
                    orderAction,
                    region,
                    newZoneText,
                    missionId,
                    addOrUpdateMission
                  )
                }
                onClick={handleBulkUpdateClick}
                loading={updateState.loading}
                data-testid="bulk-update-button"
              >
                Bulk update
              </Button>
            </Row>
          </Col>
        )}

        {/* when scanning 100 orders, bulk details rerenders affects performance */}
        {!autoUpdateEnabled && (
          <Col lg={{ span: 11, push: 1 }}>
            <BulkDetails
              updatedOrders={scanState.updatedOrders}
              recognizedOrders={scanState.recognizedOrders}
              history={props.history}
            />
          </Col>
        )}
      </Row>
    </Container>
  )
}
