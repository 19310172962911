import React from 'react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { Table as AntTable, Timeline } from 'antd'
import { OrderTransfersEventInterface } from '../../types/order-transfers.type'
import { DATE_TIME_FORMAT } from '../../utils/time'

type Props = {
  history: OrderTransfersEventInterface[]
}

const Table = styled(AntTable)`
  td {
    border-bottom: none !important;
  }

  thead th {
    color: ${(p) => p.theme.midnight500} !important;
    background-color: transparent !important;
    border: none !important;
  }

  thead th:before {
    display: none;
  }

  tbody > tr {
    background-color: transparent;
  }

  .ant-table-tbody:last-of-type > .ant-timeline-item > .ant-timeline-item-tail {
    display: none;
  }
`

const TableBody = styled.tbody`
  position: relative !important;
`

function OrderTransfersHistory(props: Props): JSX.Element {
  const dataSource = props.history.map((event, index) => {
    let sourceCourier = ''
    let destinationCourier = ''
    if (event.sourceCourier.name) {
      sourceCourier = `${event.sourceCourier.name} - ${event.sourceCourier.email}`
    }

    if (event.destinationCourier.name) {
      destinationCourier = `${event.destinationCourier.name} - ${event.destinationCourier.email}`
    }

    return {
      courier: event.courier,
      destinationMissionId: event.destinationMissionId,
      issuedBy: event.issuedBy,
      sourceMissionId: event.sourceMissionId,
      insertedAt: event.insertedAt ? format(parseISO(event.insertedAt), DATE_TIME_FORMAT) : '',
      sourceCourier: sourceCourier,
      destinationCourier: destinationCourier,
    }
  })

  const columns = [
    {
      title: 'Date',
      dataIndex: 'insertedAt',
      key: 'insertedAt',
      width: '180px',
    },
    {
      title: 'Source Mission',
      dataIndex: 'sourceMissionId',
      key: 'sourceMissionId',
      width: '180px',
    },
    {
      title: 'Source Courier',
      dataIndex: 'sourceCourier',
      key: 'sourceCourier',
      width: '180px',
    },
    {
      title: 'Destination Mission',
      dataIndex: 'destinationMissionId',
      key: 'destinationMissionId',
      width: '180px',
    },
    {
      title: 'Destination Courier',
      dataIndex: 'destinationCourier',
      key: 'destinationCourier',
      width: '180px',
    },
  ]

  return (
    <Timeline>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: undefined, x: 1200 }}
        components={{
          body: {
            row: function RenderRow({ children, ...props }: any) {
              return (
                <TableBody className="ant-table-tbody">
                  <tr {...props}>{children}</tr>
                </TableBody>
              )
            },
            wrapper: function RenderWrapper({ children }: any) {
              return <>{children}</>
            },
          },
        }}
      />
    </Timeline>
  )
}

export default OrderTransfersHistory
