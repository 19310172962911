declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: any
  }
}

interface EnvKeys {
  API_URL: string
  SITE_ENV: string
  API_GATEWAY: string
  DATADOG_APPLICATION_ID: string
  DATADOG_CLIENT_TOKEN: string
  DUBAI_DISPATCH_URL: string
  ABUDHABI_DISPATCH_URL: string
  DUBAI_BETA_DISPATCH_URL: string
  ABUDHABI_BETA_DISPATCH_URL: string
  EX_CORE_API_URL: string
  [key: string]: string
}

const ENV_JSON = '/env.json'
const ENV_VAR_ACCEPTED_VARS: (keyof EnvKeys)[] = [
  'API_URL',
  'SITE_ENV',
  'API_GATEWAY',
  'DATADOG_APPLICATION_ID',
  'DATADOG_CLIENT_TOKEN',
  'DUBAI_DISPATCH_URL',
  'ABUDHABI_DISPATCH_URL',
  'EX_CORE_API_URL',
]

export const getEnvVars = async (): Promise<EnvKeys> => {
  if (process.env.NODE_ENV === 'production') {
    const resp = await fetch(ENV_JSON)
    return resp.json()
  }
  return new Promise((resolve) => resolve(process.env as EnvKeys))
}

export const loadEnv = async (): Promise<EnvKeys> => {
  const env = await getEnvVars()

  if (process.env.NODE_ENV === 'development') {
    window.env = {
      dubaiDispatchUrl: env.REACT_APP_DUBAI_DISPATCH_URL,
      abudhabiDispatchUrl: env.REACT_APP_ABUDHABI_DISPATCH_URL,
    }
  } else {
    window.env = {
      dubaiDispatchUrl: env.DUBAI_DISPATCH_URL,
      abudhabiDispatchUrl: env.ABUDHABI_DISPATCH_URL,
    }
  }

  return ENV_VAR_ACCEPTED_VARS.reduce((acc, item) => {
    const val = env[item] || env[`REACT_APP_${item}`]

    if (val) {
      acc[item] = val
    }

    return acc
  }, {} as EnvKeys)
}
