import React from 'react'
import styled from 'styled-components'
import { space } from '@quiqupltd/ui-kit.sizes'
import { Modal as AntModal, Button, Form, Input, Alert, Select } from 'antd'
import { UpdatePaymentamountFormValues, paymentMode } from '../../types/order.type'

const Modal = styled(AntModal)`
  width: ${space(80)}px !important;
`

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (contactPhone: UpdatePaymentamountFormValues, waypointType: string) => void
  paymentMode: paymentMode
  paymentAmount: string
  isLoading: boolean
  errorMessage?: string
}

function UpdatePaymentAmountModal(props: Props): JSX.Element {
  const [form] = Form.useForm()

  function handleClose(): void {
    props.onClose()
  }

  function handleSubmit(values: UpdatePaymentamountFormValues): void {
    props.onSubmit(values, props.paymentAmount)
  }

  const paymentModeOptions = [
    { label: 'Pre paid', value: 'pre_paid' },
    { label: 'Paid on delivery', value: 'paid_on_delivery' },
  ]

  return (
    <Modal
      title="Update Payment Amount"
      visible={props.isOpen}
      footer={[
        <Button key="back" onClick={handleClose} disabled={props.isLoading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={props.isLoading}
          data-testid="update-paymentAmount-submit"
        >
          OK
        </Button>,
      ]}
      onCancel={handleClose}
    >
      <div data-testid="update-paymentAmount-modal">
        <Form
          layout="horizontal"
          onFinish={handleSubmit}
          data-testid="submission_form"
          name="submission_form"
          form={form}
          initialValues={{ paymentAmount: props.paymentAmount, paymentMode: props.paymentMode }}
        >
          <Form.Item
            label="Payment Mode"
            colon={false}
            name="paymentMode"
            rules={[
              {
                required: true,
                message: 'payment mode is required',
              },
            ]}
          >
            <Select placeholder="Select a payment mode" data-testid={'paymentMode-input'}>
              {paymentModeOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Payment Amount"
            colon={false}
            name="paymentAmount"
            rules={[
              {
                required: true,
                message: 'payment amount is required',
              },
            ]}
          >
            <Input placeholder="Payment Amount" data-testid={'paymentAmount-input'} />
          </Form.Item>
          <Form.Item
            label="Reason"
            colon={false}
            name="reason"
            rules={[
              {
                required: true,
                message: 'Reason is required',
              },
            ]}
          >
            <Input placeholder="Reason" data-testid={'reason-input'} />
          </Form.Item>
        </Form>
      </div>
      {props.errorMessage && <Alert message={props.errorMessage} type="error" showIcon />}
    </Modal>
  )
}

export default UpdatePaymentAmountModal
