import React from 'react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { Table as AntTable, Timeline } from 'antd'
import { OrderHistoryEventInterface, InternalOrder } from '../../types/history-order.type'
import { OrderStatesLabels, TransactionTypesLabels } from '../../types/order.type'
import { MissionStatesLabels } from '../../types/mission.type'
import { DATE_TIME_FORMAT, MISSION_UPDATED_FORMAT } from '../../utils/time'

type Props = {
  history: OrderHistoryEventInterface[]
}

const TimelineImageItem = styled.img`
  max-width: 100%;
  width: 60px;
`

const FailureReason = styled.span`
  color: ${(p) => p.theme.peach600};
  font-weight: 500;
`

const MissionContainer = styled.div`
  display: grid;
`

const Table = styled(AntTable)`
  td {
    border-bottom: none !important;
  }

  thead th {
    color: ${(p) => p.theme.midnight500} !important;
    background-color: transparent !important;
    border: none !important;
  }

  thead th:before {
    display: none;
  }

  tbody > tr {
    background-color: transparent;
  }

  .ant-table-tbody:last-of-type > .ant-timeline-item > .ant-timeline-item-tail {
    display: none;
  }
`
const TimelineDot = styled(Timeline.Item)`
  position: absolute !important;
  height: 100% !important;
  transform: translateY(calc(50% - 5px)) !important;
  padding-bottom: 0 !important;
  z-index: 999;
`

const TableBody = styled.tbody`
  position: relative !important;
`

export function getMissionUpdatedAt(event: OrderHistoryEventInterface): string {
  if (event.internalOrder) {
    return `${MissionStatesLabels[event.internalOrder.mission.state]} - ${
      event.internalOrder.mission.stateUpdatedAt
        ? format(parseISO(event.internalOrder.mission.stateUpdatedAt), MISSION_UPDATED_FORMAT)
        : ''
    }`
  }

  return ''
}

export function getMission(event: OrderHistoryEventInterface): string {
  if (event.internalOrder) {
    return `${event.internalOrder.type} - ${event.internalOrder.mission.id}`
  }

  return ''
}

function getSignature(internalOrder: InternalOrder | undefined): string {
  if (
    internalOrder &&
    internalOrder.destination &&
    internalOrder.destination.signature &&
    internalOrder.destination.signature.url
  ) {
    return internalOrder.destination.signature.url
  }

  return ''
}

function getIdentification(internalOrder: InternalOrder | undefined): string {
  if (
    internalOrder &&
    internalOrder.destination &&
    internalOrder.destination.customerIdentificationPhoto &&
    internalOrder.destination.customerIdentificationPhoto.url
  ) {
    return internalOrder.destination.customerIdentificationPhoto.url
  }

  return ''
}

function getProofOfDeliveryDocuments(internalOrder: InternalOrder | undefined): string[] {
  return internalOrder?.destination?.proofOfDeliveryPhotos ? internalOrder.destination.proofOfDeliveryPhotos : []
}

function getTransactionType(internalOrder: InternalOrder | undefined): string {
  if (internalOrder && internalOrder.destination.receipts.length) {
    return TransactionTypesLabels[internalOrder.destination.receipts[0].transactionType]
  }

  return ''
}

function ImageItem(props: { url: string; name?: string }): JSX.Element {
  return (
    <>
      {props.name ? <span>{props.name}:</span> : ''}
      <TimelineImageItem src={props.url} alt={props.name} />
    </>
  )
}

function OrderHistory(props: Props): JSX.Element {
  const dataSource = props.history.map((event, index) => {
    const { internalOrder } = event
    const signature = getSignature(internalOrder)
    const identification = getIdentification(internalOrder)
    const proofOfDeliveryDocuments = getProofOfDeliveryDocuments(internalOrder)
    const failureReason = internalOrder && internalOrder.deliveryFailureReason
    const state = OrderStatesLabels[event.toState]
    const transactionType = getTransactionType(internalOrder)

    return {
      date: event.occurredAt ? format(parseISO(event.occurredAt), DATE_TIME_FORMAT) : '',
      orderStatus: state,
      mission: getMission(event) ?? '',
      jobId: (internalOrder && internalOrder.jobId) ?? '',
      missionStateUpdatedAt: getMissionUpdatedAt(event) ?? '',
      user: event?.author?.fullname ?? '',
      custodian: event.custodian ? `${event.custodian.custodianName} (${event.custodian.custodianType})` : '',
      signature,
      identification,
      proofOfDeliveryDocuments,
      failureReason,
      onHoldReason: event.onHoldReason ? event.onHoldReason : '',
      returnToOriginReason: event.returnToOriginReason ? event.returnToOriginReason : '',
      transactionType,
      key: `row-${index}`,
    }
  })

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '180px',
    },
    {
      title: 'Order status',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: '180px',
    },
    {
      title: 'Mission',
      dataIndex: 'mission',
      key: 'mission',
    },
    {
      title: 'Job Id',
      dataIndex: 'jobId',
      key: 'jobId',
    },
    {
      title: 'Mission state updated at',
      dataIndex: 'missionStateUpdatedAt',
      key: 'missionStateUpdatedAt',
      render: function RenderMission(value: string, record: any) {
        return (
          <MissionContainer>
            <span>{value}</span>
            {record.orderStatus === OrderStatesLabels.delivery_complete && record.signature && (
              <ImageItem url={record.signature} name="Signature" />
            )}

            {record.orderStatus === OrderStatesLabels.delivery_complete && record.identification && (
              <ImageItem url={record.identification} name="Identification " />
            )}

            <div>
              {record.orderStatus === OrderStatesLabels.delivery_complete &&
              record.proofOfDeliveryDocuments &&
              record.proofOfDeliveryDocuments.length >= 1 ? (
                <span> POD photo(s):</span>
              ) : (
                ''
              )}

              {record.orderStatus === OrderStatesLabels.delivery_complete &&
                record.proofOfDeliveryDocuments &&
                record.proofOfDeliveryDocuments.map((podImage: string) =>
                  podImage ? <ImageItem url={podImage} /> : ''
                )}
            </div>
            {(record.orderStatus === OrderStatesLabels.delivery_failed ||
              record.orderStatus === OrderStatesLabels.collection_failed) &&
              record.failureReason && <FailureReason>{record.failureReason}</FailureReason>}

            {record.orderStatus === OrderStatesLabels.delivery_complete && record.transactionType && (
              <span>Payment type: {record.transactionType}</span>
            )}
          </MissionContainer>
        )
      },
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Custodian',
      dataIndex: 'custodian',
      key: 'custodian',
    },
    {
      title: 'On hold reason',
      dataIndex: 'onHoldReason',
      key: 'onHoldReason',
    },
    {
      title: 'Return to origin reason',
      dataIndex: 'returnToOriginReason',
      key: 'returnToOriginReason',
    },
  ]

  return (
    <Timeline>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: undefined, x: 1200 }}
        components={{
          body: {
            row: function RenderRow({ children, ...props }: any) {
              return (
                <TableBody className="ant-table-tbody">
                  <TimelineDot>{''}</TimelineDot>
                  <tr {...props}>{children}</tr>
                </TableBody>
              )
            },
            wrapper: function RenderWrapper({ children }: any) {
              return <>{children}</>
            },
          },
        }}
      />
    </Timeline>
  )
}

export default OrderHistory
