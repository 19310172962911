import { useReducer, useEffect } from 'react'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { OrderTransfersEventInterface } from '../types/order-transfers.type'
import { loadEnv } from '../configEnv'

interface StateInterface {
  result: OrderTransfersEventInterface[] | []
  loading: boolean
  error: null | string
}

enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

const initialState: StateInterface = {
  result: [],
  error: null,
  loading: false,
}

async function fetchOrderTransfers(uuid: string | undefined): Promise<OrderTransfersEventInterface[]> {
  const env = await loadEnv()
  return ApiClient.get({ path: env.EX_CORE_API_URL + `/api/missions/${uuid}/transfers` })
}

// Reducer
type Action =
  | { type: actions.FETCH_INIT }
  | { type: actions.FETCH_SUCCESS; response: OrderTransfersEventInterface[] }
  | { type: actions.FETCH_FAILURE; error: string }

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      return { ...state, result: action.response, loading: false }
    }
    case actions.FETCH_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    default:
      return state
  }
}

export function useOrderTransfers(uuid: string | undefined): StateInterface {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function setOrderTransfers(): Promise<void> {
      try {
        dispatch({ type: actions.FETCH_INIT })
        const response = await fetchOrderTransfers(uuid)

        dispatch({ type: actions.FETCH_SUCCESS, response })
      } catch (error) {
        dispatch({ type: actions.FETCH_FAILURE, error })
      }
    }
    if (uuid) {
      setOrderTransfers()
    }
  }, [uuid])

  return state
}
