import React, { useReducer, useEffect, useState, useCallback } from 'react'
import { Orders } from '@quiqupltd/quiqupjs'
import merge from 'lodash/merge'
import { notification } from 'antd'
import {
  OrderWithScanned,
  OrderInterface,
  OrderKinds,
  ItemsEntity,
  OrderStates,
  StateMapped,
  onHoldReasons,
} from '../types/order.type'
import { useOrderUpdate } from './order-update'
import { ActionType, areAllItemsScanned, scanMissionIntentions } from '../components/BulkActions/BulkActions'
import { Depot } from '../types/history-order.type'

// Interfaces
interface StateInterface {
  loading: boolean
  loadingBatch: boolean
  error: null | string
  recognizedOrders: OrderWithScanned[]
  unrecognizedValues: string[]
  updatedOrders: OrderWithScanned[]
  failedToUpdateOrders: string[]
}

enum OrderFoundBy {
  ORDER_ID = 'order_id',
  PARCEL_BARCODE = 'parcel_barcode',
}

interface OrderByIdOrBarcode {
  order: OrderInterface
  foundBy?: OrderFoundBy
  error: null | string
}

interface ActionsInterface {
  removeRecognizedOrder(orderId: string): void
  addRecognizedBatch(orders: OrderInterface[]): void
}

interface HookInterface {
  scanState: StateInterface
  scanActions: ActionsInterface
}

interface ErrorInterface {
  title?: string
  description: string
  link?: { text: string; route: string }
}

// Constants
enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_BATCH_INIT = 'FETCH_BATCH_INIT',
  FETCH_BATCH_FINISHED = 'FETCH_BATCH_FINISHED',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
  ADD_RECOGNIZED_BATCH = 'ADD_RECOGNIZED_BATCH',
  ADD_UNRECOGNIZED_BATCH = 'ADD_UNRECOGNIZED_BATCH',
  REMOVE_RECOGNIZED_ORDER = 'REMOVE_RECOGNIZED_ORDER',
  UPDATE_UPDATED_ORDERS = 'UPDATE_UPDATED_ORDERS',
}

const initialState: StateInterface = {
  loading: false,
  loadingBatch: false,
  error: null,
  recognizedOrders: [],
  unrecognizedValues: [],
  updatedOrders: [],
  failedToUpdateOrders: [],
}

// Helpers
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const reflect = (p: Promise<unknown>) =>
  p.then(
    (result: unknown) => ({ result, status: 'fulfilled' }),
    (error: unknown) => ({ error, status: 'rejected' })
  )

function removeOrderById(orders: OrderWithScanned[], id: string): OrderWithScanned[] {
  return orders.filter((order) => order.id.toString() !== id)
}

function isOrderAddedInArray(orders: OrderWithScanned[], order: OrderWithScanned): boolean {
  return orders.some((o) => o.id === order.id)
}

function scanItem(order: OrderWithScanned, itemBarcode: string): OrderWithScanned {
  order.items = order.items.map((item) => (item.parcelBarcode === itemBarcode ? { ...item, scanned: true } : item))
  return order
}

function getOrderWithScannedItems(
  order: OrderWithScanned,
  recognizedOrders: OrderWithScanned[],
  itemBarcode: string
): OrderWithScanned {
  const currentOrder = recognizedOrders.find((o) => o.id === order.id)

  return scanItem(merge(order, currentOrder), itemBarcode)
}

function getRecognizedOrders(
  currentRecognized: OrderWithScanned[],
  newRecognized: OrderWithScanned
): OrderWithScanned[] {
  if (isOrderAddedInArray(currentRecognized, newRecognized)) {
    return currentRecognized.map((order) => {
      if (order.id === newRecognized.id) {
        return newRecognized
      }
      return order
    })
  }

  return [newRecognized, ...currentRecognized]
}

function getWarningNotification({ order, error }: OrderByIdOrBarcode, intention?: string): ErrorInterface | null {
  if (order.printLabel) {
    return { description: `Order ${order.id}: Address has been updated, print the label` }
  }

  if (error) {
    return { description: `Order ${order.id}: ${error}` }
  }

  if (order.state === OrderStates.cancelled) {
    return {
      description: `Order ${order.id} is in “Cancelled” state. Please check if you want to update the status
      `,
    }
  }
  if (order.state === OrderStates.on_hold && order.onHoldReason === onHoldReasons.unableToDispatchNow) {
    return {
      description: `Order ${order.id} is “On hold” with reason “Unable to Dispatch Now”. If you want to update the status go to the order and update with "Take action" button
      `,
      link: { text: `Order ${order.id}: `, route: `/order/${order.id}` },
    }
  }

  if (intention === OrderStates.at_depot || intention === OrderStates.received_at_depot) {
    if (order.deliveryTime?.deliveryBefore) {
      if (order.deliveryAttempts >= 3) {
        return {
          description: `Order ${order.id} has been attempted more than 3 time. Please move it “Return to origin” status
          `,
        }
      }
    }
  }

  return null
}

function getValidationError(
  { order, foundBy, error }: OrderByIdOrBarcode,
  recognizedOrders: OrderWithScanned[],
  intention: string
): ErrorInterface | null {
  if (error) {
    return { description: `Order ${order.id}: ${error}` }
  }

  if (intention === StateMapped.atDepot && order.state === OrderStates.delivery_complete) {
    return {
      title: `Can't update orders in "Delivery Complete"`,
      description: `You can’t move multiple orders that are in delivery complete state. You can open each order separately and update with "Take action" button
      `,
      link: { text: `Order ${order.id}: `, route: `/order/${order.id}` },
    }
  }

  if (order.kind !== OrderKinds.partner_same_day) {
    return { description: `Order ${order.id} was found but is not of type same_day` }
  }

  if (order.state === OrderStates.pending) {
    return {
      description: `Order ${order.id} was found but in a pending state. Partner needs to put order in Ready For Collection`,
    }
  }

  if (foundBy === OrderFoundBy.ORDER_ID) {
    if (order.items.length > 1) {
      return { description: `Order ${order.id} must be scanned by its items barcodes` }
    }

    if (isOrderAddedInArray(recognizedOrders, order)) {
      return { description: `Order ${order.id} has been added already as recognized` }
    }
  }

  return null
}

function getScanIntention(orderAction: string): string {
  return scanMissionIntentions[orderAction] || orderAction
}

// Reducer
type Action =
  | { type: actions.FETCH_INIT }
  | { type: actions.FETCH_BATCH_INIT }
  | { type: actions.FETCH_BATCH_FINISHED }
  | {
      type: actions.FETCH_SUCCESS
      order: OrderWithScanned
      itemBarcode: string
    }
  | { type: actions.FETCH_FAILURE; value: string; error: string }
  | { type: actions.ADD_UNRECOGNIZED_BATCH; orders: string[] }
  | { type: actions.ADD_RECOGNIZED_BATCH; orders: OrderInterface[] }
  | { type: actions.REMOVE_RECOGNIZED_ORDER; value: string }
  | { type: actions.UPDATE_UPDATED_ORDERS; orders: OrderInterface[] }

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_BATCH_INIT:
      return { ...state, loadingBatch: true }
    case actions.FETCH_BATCH_FINISHED:
      return { ...state, loadingBatch: false }
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      const recognizedOrders = getRecognizedOrders(state.recognizedOrders, action.order)
      return { ...state, loading: false, error: null, recognizedOrders }
    }
    case actions.FETCH_FAILURE: {
      return {
        ...state,
        loading: false,
        unrecognizedValues: [action.value, ...state.unrecognizedValues],
        error: action.error,
      }
    }
    case actions.ADD_UNRECOGNIZED_BATCH: {
      return {
        ...state,
        loadingBatch: false,
        unrecognizedValues: [...action.orders, ...state.unrecognizedValues],
      }
    }
    case actions.ADD_RECOGNIZED_BATCH: {
      const scannedOrders = action.orders.map((order) => ({
        ...order,
        items: order.items.map((item) => ({ ...item, scanned: true })),
      }))

      return { ...state, recognizedOrders: scannedOrders, loadingBatch: false }
    }
    case actions.REMOVE_RECOGNIZED_ORDER: {
      return {
        ...state,
        recognizedOrders: removeOrderById(state.recognizedOrders, action.value),
      }
    }
    case actions.UPDATE_UPDATED_ORDERS: {
      return {
        ...state,
        updatedOrders: action.orders,
      }
    }
    default:
      return state
  }
}

// Hook
export function useOrderScan(
  value: string,
  updateAction: ActionType | null,
  shouldAutoUpdate: boolean,
  region?: Region.Code | null,
  depot?: Depot | null
): HookInterface {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [autoUpdateValue, setAutoUpdateValue] = useState('')
  const { updateActions, updateState } = useOrderUpdate(
    [autoUpdateValue],
    shouldAutoUpdate && updateAction ? updateAction : null,
    region,
    depot
  )

  const updateOrder = useCallback(
    (order: OrderWithScanned): void => {
      if (isOrderAddedInArray(state.updatedOrders, order)) {
        notification.warn({
          message: 'Warning',
          description: `This item has already been scanned and won't be duplicated.`,
          duration: 3,
        })
      } else {
        setAutoUpdateValue(String(order.id))
      }
    },
    [state.updatedOrders]
  )

  useEffect(() => {
    const newUpdatedOrders = updateState.updatedOrders.map((order) => {
      const newOrder = { ...order }

      newOrder.items = newOrder.items.map((item: ItemsEntity) => ({
        ...item,
        scanned: true,
      }))
      return newOrder
    })

    dispatch({
      type: actions.UPDATE_UPDATED_ORDERS,
      orders: newUpdatedOrders,
    })
  }, [updateState.updatedOrders])

  const removeRecognizedOrder = useCallback(function removeRecognizedOrder(orderValue: string): void {
    dispatch({ type: actions.REMOVE_RECOGNIZED_ORDER, value: orderValue })
  }, [])

  const addRecognizedBatch = useCallback(function addRecognizedBatch(orders: OrderInterface[]): void {
    dispatch({
      type: actions.ADD_RECOGNIZED_BATCH,
      orders,
    })
  }, [])

  useEffect(() => {
    const noDeliveryAudio = new Audio('https://storage.googleapis.com/quiqup-assets/oms/no_delivery_scan.mp3')

    async function fetchMultipleOrders(): Promise<void> {
      dispatch({ type: actions.FETCH_BATCH_INIT })

      if (value && updateAction) {
        const intention = getScanIntention(updateAction)
        const values = value.split(' ')
        const arrPromises = values.map((v: string) => reflect(Orders.getByIdOrBarcode(v, intention)))
        const results = await Promise.all(arrPromises)
        const validResults: OrderWithScanned[] = []
        const failedResults: string[] = []
        const invalidResults: ErrorInterface[] = []

        // TODO, replace the reflect helper
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        results.forEach((r: any, i) => {
          if (r.status === 'fulfilled') {
            const { order, foundBy, error } = r.result

            const validationError = getValidationError({ order, foundBy, error }, state.recognizedOrders, intention)

            const noDeliveryNotification = getWarningNotification({ order, error }, intention)
            if (noDeliveryNotification) {
              noDeliveryAudio.play()
              notification.warn({
                message: noDeliveryNotification.title ?? 'Warning',
                description: (
                  <>
                    {noDeliveryNotification.link ? (
                      <a href={noDeliveryNotification.link?.route}>{noDeliveryNotification.link?.text}</a>
                    ) : null}
                    {noDeliveryNotification.description}
                  </>
                ),
                duration: 0,
              })
            }

            if (!validationError) {
              const itemBarcode = foundBy === OrderFoundBy.ORDER_ID ? order.items[0].parcelBarcode : values[i]
              const newOrder = getOrderWithScannedItems(order, state.recognizedOrders, itemBarcode)
              validResults.push(newOrder)
            } else {
              invalidResults.push(validationError)
            }
          } else {
            failedResults.push(values[i])
          }
        })

        if (validResults.length > 0) {
          dispatch({
            type: actions.ADD_RECOGNIZED_BATCH,
            orders: validResults,
          })
        }

        if (invalidResults.length > 0) {
          invalidResults.forEach((r) => {
            notification.error({
              message: r.title ?? 'Error',
              description: (
                <>
                  {r.link ? <a href={r.link?.route}>{r.link?.text}</a> : null}
                  {r.description}
                </>
              ),
              duration: 0,
            })
          })
        }

        if (failedResults.length > 0) {
          dispatch({
            type: actions.ADD_UNRECOGNIZED_BATCH,
            orders: failedResults,
          })
        }
      }

      dispatch({ type: actions.FETCH_BATCH_FINISHED })
    }

    async function fetchOrder(): Promise<void> {
      if (value && updateAction) {
        const intention = getScanIntention(updateAction)
        dispatch({ type: actions.FETCH_INIT })
        try {
          const { order, foundBy, error }: OrderByIdOrBarcode = await Orders.getByIdOrBarcode(value, intention)

          // Validate order

          const validationError = getValidationError({ order, foundBy, error }, state.recognizedOrders, intention)

          if (validationError) throw validationError

          const noDeliveryNotification = getWarningNotification({ order, error }, intention)
          if (noDeliveryNotification) {
            noDeliveryAudio.play()
            notification.warn({
              message: noDeliveryNotification.title ?? 'Warning',
              description: (
                <>
                  {noDeliveryNotification.link ? (
                    <a href={noDeliveryNotification.link?.route}>{noDeliveryNotification.link?.text}</a>
                  ) : null}
                  {noDeliveryNotification.description}
                </>
              ),
              duration: 0,
            })
          }

          // Order with its previous scanned items + new scanned item
          const itemBarcode = foundBy === OrderFoundBy.ORDER_ID ? order.items[0].parcelBarcode : value
          const newOrder = getOrderWithScannedItems(order, state.recognizedOrders, itemBarcode)

          if (foundBy === OrderFoundBy.ORDER_ID) {
            dispatch({
              type: actions.FETCH_SUCCESS,
              order: newOrder,
              itemBarcode,
            })
            updateOrder(newOrder)

            return
          }

          // Scanned by barcode
          dispatch({
            type: actions.FETCH_SUCCESS,
            order: newOrder,
            itemBarcode,
          })
          if (areAllItemsScanned([newOrder])) {
            updateOrder(newOrder)
          }
        } catch (error) {
          if (state.unrecognizedValues.includes(value)) {
            notification.error({
              message: 'Error',
              description: `Order ${value} has been added already as unrecognized`,
              duration: 0,
            })

            return
          }

          if (!error.error) {
            notification.error({
              message: error.title ?? 'Error',
              description: (
                <>
                  {error.link ? <a href={error.link?.route}>{error.link?.text}</a> : null}
                  {error.description}
                </>
              ),
              duration: 0,
            })

            return
          }

          notification.error({
            message: 'Error',
            description: error.apiError?.human,
            duration: 0,
          })
          dispatch({ type: actions.FETCH_FAILURE, value, error: error.error })
        }
      }
    }

    if (value.split(' ').length > 1) {
      fetchMultipleOrders()
    } else {
      fetchOrder()
    }
  }, [value, state.recognizedOrders, state.unrecognizedValues, updateAction, updateOrder])

  useEffect(() => {
    if (autoUpdateValue) {
      updateActions.updateOrders(null)
    }
    // TODO: test adding the dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoUpdateValue])

  useEffect(() => {
    if (updateState.updatedOrders) {
      updateState.updatedOrders.forEach((order) => {
        const recognizedOrder = state.recognizedOrders.find((o) => o.id === order.id)

        if (recognizedOrder) {
          dispatch({
            type: actions.REMOVE_RECOGNIZED_ORDER,
            value: String(recognizedOrder.id),
          })
        }
      })
    }
  }, [updateState.updatedOrders, state.recognizedOrders])

  useEffect(() => {
    if (updateState.failedOrders) {
      state.recognizedOrders.forEach((order) => {
        if (updateState.failedOrders.includes(String(order.id))) {
          dispatch({
            type: actions.REMOVE_RECOGNIZED_ORDER,
            value: order.id.toString(),
          })
        }
      })
    }
  }, [updateState.failedOrders, state.recognizedOrders])

  return {
    scanState: {
      ...state,
      failedToUpdateOrders: updateState.failedOrders,
    },
    scanActions: { removeRecognizedOrder, addRecognizedBatch },
  }
}
