import React from 'react'
import styled from 'styled-components'
import { Table as AntTable, Timeline } from 'antd'
import { PaymentChangesEventInterface } from '../../types/payment-changes.type'

type Props = {
  history: PaymentChangesEventInterface[]
}

const Table = styled(AntTable)`
  td {
    border-bottom: none !important;
  }

  thead th {
    color: ${(p) => p.theme.midnight500} !important;
    background-color: transparent !important;
    border: none !important;
  }

  thead th:before {
    display: none;
  }

  tbody > tr {
    background-color: transparent;
  }

  .ant-table-tbody:last-of-type > .ant-timeline-item > .ant-timeline-item-tail {
    display: none;
  }
`

const TableBody = styled.tbody`
  position: relative !important;
`

function PaymentHistory(props: Props): JSX.Element {
  const dataSource = props.history.map((event, index) => {
    return {
      orderUuid: event.orderUuid,
      coreApiOrderId: event.coreApiOrderId,
      paymentAmount: event.paymentAmount,
      paymentMode: event.paymentMode,
      reason: event.reason,
      author: event.author,
      user: event?.author ?? '',
    }
  })

  const columns = [
    {
      title: 'Order UUID',
      dataIndex: 'orderUuid',
      key: 'orderUuid',
      width: '425px',
    },
    {
      title: 'Payment amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      width: '180px',
    },
    {
      title: 'Payment mode',
      dataIndex: 'paymentMode',
      key: 'paymentMode',
      width: '180px',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
    },
  ]

  return (
    <Timeline>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: undefined, x: 1200 }}
        components={{
          body: {
            row: function RenderRow({ children, ...props }: any) {
              return (
                <TableBody className="ant-table-tbody">
                  <tr {...props}>{children}</tr>
                </TableBody>
              )
            },
            wrapper: function RenderWrapper({ children }: any) {
              return <>{children}</>
            },
          },
        }}
      />
    </Timeline>
  )
}

export default PaymentHistory
