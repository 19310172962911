/* eslint-disable @typescript-eslint/indent */
import { useReducer, useEffect } from 'react'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { format, subYears, addYears, subMonths, addMonths } from 'date-fns'
import { OrderInterface, OrderStates, OrderKinds, AddressChecked, PrintLabelChecked } from '../types/order.type'
import { DATE_FORMAT } from '../utils/time'
import { loadEnv } from '../configEnv'
import moment from 'moment'
// Interfaces
interface StateInterface {
  currentPage: number
  perPage: number
  results: OrderInterface[]
  total: number
  totalPages: number
  error: null | string
  loading: boolean
}

interface OrdersApiInterface {
  results: OrderInterface[]
  total: number
}

type FetchOrdersResponse = OrdersApiInterface & {
  currentPage: number
  totalPages: number
}

export type AllowedOrderByType =
  | 'created_at_asc'
  | 'created_at_desc'
  | 'delivery_before_asc'
  | 'delivery_before_desc'
  | 'delivery_attempts_asc'
  | 'delivery_attempts_desc'
  | 'state_updated_asc'
  | 'state_updated_desc'

type OrdersQueryStringParams = {
  [key: string]: unknown
  page: number | string
  perPage: number
  from: string
  to: string
  'filters[state]'?: string
  'filters[client]'?: string
  'filters[customer_name]'?: string
  'filters[customer_phone]'?: string
  'filters[order_id]'?: string
  'filters[partner_order_id]'?: string
  'filters[shipment_number]'?: string
  'filters[service_kind]'?: string
  'filters[region]'?: string
  'filters[destination_region]'?: string
  'filters[checked_location]'?: boolean
  orderBy?: AllowedOrderByType
}

export type allowedSearchType =
  | 'customer_name'
  | 'customer_phone'
  | 'client'
  | 'shipment_number'
  | 'order_id'
  | 'partner_order_id'

export interface OrdersParams {
  page: number | string | null
  states: OrderStates[]
  kinds: OrderKinds[]
  deliveryBeforeDates?: string[]
  isAddressChecked?: AddressChecked
  isPrintLabelChecked?: PrintLabelChecked
  searchType?: allowedSearchType | ''
  searchText?: string
  sortBy?: AllowedOrderByType | null
  region?: Region.Code
  zones?: string[]
  dateType?: string
  dateRange?: string[]
  destinationRegion?: Region.Code
}

// Constants
export const ORDERS_PER_PAGE = 50
enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

const initialState: StateInterface = {
  currentPage: 0,
  perPage: ORDERS_PER_PAGE,
  results: [],
  total: 0,
  totalPages: 0,
  error: null,
  loading: false,
}

// Helpers
export function getDefaultRange(): { from: string; to: string } {
  return {
    from: format(subYears(Date.now(), 1), DATE_FORMAT),
    to: format(addYears(Date.now(), 1), DATE_FORMAT),
  }
}
export function getOneMonthRange(): { oneMonthFrom: string; oneMonthTo: string } {
  return {
    oneMonthFrom: format(subMonths(Date.now(), 1), DATE_FORMAT),
    oneMonthTo: format(addMonths(Date.now(), 1), DATE_FORMAT),
  }
}

function getSearchText(type: allowedSearchType | '', text: string): string {
  if (type === 'customer_phone') {
    return text.replace('+', '%2B')
  }

  if (type === 'order_id' || type === 'shipment_number' || type === 'client') {
    return text
      .split(' ')
      .filter(Boolean) // removes extra spaces
      .join(',')
  }

  return text
}

async function fetchOrders(
  page: number | string | null,
  from: string,
  to: string,
  states: OrderStates[],
  kinds: OrderKinds[],
  quickDeliveryBeforeDates?: string[],
  isAddressChecked?: AddressChecked,
  isPrintLabelChecked?: PrintLabelChecked,
  searchType?: allowedSearchType | '',
  searchText?: string,
  sortBy?: AllowedOrderByType | null,
  region?: Region.Code,
  zones?: string[],
  dateType?: string,
  dateRange?: string[],
  destinationRegion?: Region.Code
): Promise<FetchOrdersResponse> {
  if (!page) return Promise.reject()

  const queryParts: OrdersQueryStringParams = {
    version: 'v2',
    page,
    perPage: ORDERS_PER_PAGE,
    from,
    to,
  }

  const env = await loadEnv()

  if (states.length > 0) queryParts['filters[state]'] = states.join(',')
  if (kinds.length > 0) queryParts['filters[service_kind]'] = kinds.join(',')
  queryParts['filters[checked_location]'] = isAddressChecked
  queryParts['filters[print_label]'] = isPrintLabelChecked

  if (searchType && searchText) {
    const filter = `filters[${searchType}]` as keyof OrdersQueryStringParams
    queryParts[filter] = getSearchText(searchType, searchText)
  }

  if (region) {
    queryParts['filters[region]'] = region
  }

  if (sortBy) queryParts.orderBy = sortBy

  if (zones?.length) {
    queryParts['zones[]'] = zones.join(',').split(' ').join('+')
  }

  if (dateType && dateRange?.length) {
    if (dateRange[0] !== '') {
      queryParts[`${dateType}_from`] = dateRange[0]

      if (dateRange.length > 1) {
        if (dateRange[1] === '') {
          queryParts[`${dateType}_to`] = dateRange[0]
        } else {
          queryParts[`${dateType}_to`] = dateRange[1]
        }
      } else {
        queryParts[`${dateType}_to`] = dateRange[0]
      }
    }
  }

  if (quickDeliveryBeforeDates?.length) {
    if (quickDeliveryBeforeDates.length > 1) {
      queryParts['delivery_before_from'] = moment(quickDeliveryBeforeDates[0]).isBefore(quickDeliveryBeforeDates[1])
        ? quickDeliveryBeforeDates[0]
        : quickDeliveryBeforeDates[1]
      queryParts['delivery_before_to'] = quickDeliveryBeforeDates.filter(
        (date) => date !== queryParts['delivery_before_from']
      )[0]
    } else {
      queryParts['delivery_before_from'] = quickDeliveryBeforeDates[0]
      queryParts['delivery_before_to'] = quickDeliveryBeforeDates[0]
    }
  }
  if (destinationRegion) {
    queryParts['filters[destination_region]'] = destinationRegion
  }

  const apiOrders: FetchOrdersResponse = await ApiClient.get({
    path: env.EX_CORE_API_URL + '/orders',
    params: queryParts,
  })

  return apiOrders
}

type ActionInit = {
  type: actions.FETCH_INIT
}

type ActionSuccess = {
  type: actions.FETCH_SUCCESS
  response: FetchOrdersResponse
}

type ActionFailure = {
  type: actions.FETCH_FAILURE
  error: string
}

type Action = ActionInit | ActionSuccess | ActionFailure

// Reducer
function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      const { currentPage, results, total, totalPages } = action.response
      return {
        ...state,
        currentPage,
        results,
        total,
        totalPages,
        error: null,
        loading: false,
      }
    }
    case actions.FETCH_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    default:
      return state
  }
}

// Hook
const { from, to } = getDefaultRange()

export function useOrders({
  page,
  states,
  kinds,
  deliveryBeforeDates,
  isAddressChecked,
  isPrintLabelChecked,
  searchType,
  searchText,
  sortBy,
  region,
  zones,
  dateType,
  dateRange,
  destinationRegion,
}: OrdersParams): StateInterface {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function setOrders(): Promise<void> {
      try {
        dispatch({ type: actions.FETCH_INIT })
        const response = await fetchOrders(
          page,
          from,
          to,
          states,
          kinds,
          deliveryBeforeDates,
          isAddressChecked,
          isPrintLabelChecked,
          searchType,
          searchText,
          sortBy,
          region,
          zones,
          dateType,
          dateRange,
          destinationRegion
        )

        dispatch({ type: actions.FETCH_SUCCESS, response })
      } catch (error) {
        dispatch({ type: actions.FETCH_FAILURE, error })
      }
    }
    if (page && page > 0) {
      setOrders()
    }
  }, [
    page,
    states,
    deliveryBeforeDates,
    isAddressChecked,
    isPrintLabelChecked,
    searchType,
    searchText,
    sortBy,
    kinds,
    region,
    zones,
    dateType,
    dateRange,
    destinationRegion,
  ])

  return state
}
