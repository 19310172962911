import { useReducer, useEffect } from 'react'
import { ApiClient } from '@quiqupltd/quiqupjs'
import { PaymentChangesEventInterface } from '../types/payment-changes.type'
import { loadEnv } from '../configEnv'

interface StateInterface {
  result: PaymentChangesEventInterface[] | []
  loading: boolean
  error: null | string
}

enum actions {
  FETCH_INIT = 'FETCH_INIT',
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILURE = 'FETCH_FAILURE',
}

const initialState: StateInterface = {
  result: [],
  error: null,
  loading: false,
}

async function fetchPaymentChanges(uuid: string | undefined): Promise<PaymentChangesEventInterface[]> {
  const env = await loadEnv()
  return ApiClient.get({ path: env.EX_CORE_API_URL + `/orders/${uuid}/payment_changes` })
}

// Reducer
type Action =
  | { type: actions.FETCH_INIT }
  | { type: actions.FETCH_SUCCESS; response: PaymentChangesEventInterface[] }
  | { type: actions.FETCH_FAILURE; error: string }

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.FETCH_INIT:
      return { ...state, loading: true }
    case actions.FETCH_SUCCESS: {
      return { ...state, result: action.response, loading: false }
    }
    case actions.FETCH_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    default:
      return state
  }
}

export function usePaymentChanges(uuid: string | undefined): StateInterface {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function setPaymentChanges(): Promise<void> {
      try {
        dispatch({ type: actions.FETCH_INIT })
        const response = await fetchPaymentChanges(uuid)

        dispatch({ type: actions.FETCH_SUCCESS, response })
      } catch (error) {
        dispatch({ type: actions.FETCH_FAILURE, error })
      }
    }
    if (uuid) {
      setPaymentChanges()
    }
  }, [uuid])

  return state
}
